/* tailwind css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* all */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

/* html */
html {
    
    /* height: 100%; */
}

/* body */
body {
    height: 100vh;
    scroll-behavior: smooth;
    /* overflow: auto; */
    width: 100%;
}

/* scrollbar */
/* ::-webkit-scrollbar{
    display: none;
} */

/* anchor tag */
/* a, a:hover, a:focus, a:active{
    color: inherit;
    text-decoration: none;
} */


/* body {
	font-family: 'Poppins-Medium', 'Poppins-Bold, Poppins-Light';
} */


/* Fonts */
@font-face {
    font-family: 'Poppins-Thin';
    src: url('./fonts/poppins/Poppins-Thin.ttf')format('ttf'), url('./fonts/poppins/Poppins-ThinItalic.ttf')format('ttf');
    font-weight: 100;
    font-display: swap;
    font-style: normal;
}

.font-poppins-thin {
    font-family: 'Poppins-Thin';
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('./fonts/poppins/Poppins-Light.ttf')format('ttf'), url('./fonts/poppins/Poppins-ExtraLight.ttf')format('ttf'), url('./fonts/poppins/Poppins-LightItalic.ttf')format('ttf'), url('./fonts/poppins/Poppins-ExtraLightItalic.ttf')format('ttf');
    font-weight: 200 300;
    font-display: swap;
    font-style: normal;
}

.font-poppins-light {
    font-family: 'Poppins-Light';
}


@font-face {
    font-family: 'Poppins-Regular';
    src: url('./fonts/poppins/Poppins-Regular.ttf')format('ttf');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

.font-poppins-regular {
    font-family: 'Poppins-Regular';
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('./fonts/poppins/Poppins-Medium.ttf')format('ttf');
    /* url('./fonts/poppins/Poppins-MediumItalic.ttf')format('ttf'); */
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

.font-poppins-medium {
    font-family: 'Poppins-Medium';
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./fonts/poppins/Poppins-Bold.ttf')format('ttf'), url('./fonts/poppins/Poppins-SemiBold.ttf')format('ttf'), url('./fonts/poppins/Poppins-ExtraBold.ttf')format('ttf'), url('./fonts/poppins/Poppins-BoldItalic.ttf')format('ttf'), url('./fonts/poppins/Poppins-SemiBoldItalic.ttf')format('ttf'), url('./fonts/poppins/Poppins-ExtraBoldItalic.ttf')format('ttf');
    font-weight: 600 700 800;
    font-display: swap;
    font-style: normal;
}

.font-poppins-bold {
    font-family: 'Poppins-Bold';
}

@font-face {
    font-family: 'Poppins-Black';
    src: url('./fonts/poppins/Poppins-Black.ttf')format('ttf'), url('./fonts/poppins/Poppins-BlackItalic.ttf')format('ttf');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
}

.font-poppins-black {
    font-family: 'Poppins-Black';
}


/* Scroll to top style */
.scrollToTop {
    /* @apply bg-indigo-600; */
    /* @apply text-white; */
    position: fixed;
    width: 100%;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 999;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.9;
}

.scrollToTop:hover {
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}

.contentBefor {
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 32px;
    line-height: 60px;
    color: #2E3B5E;
    font-size: normal;
    font-weight: 700;
    text-transform: uppercase;
}

